
.multiple-checkbox {
    margin-bottom: 10px;
    .el-checkbox {
        display: block;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .single-input {
        width: 700px;
    }
}
.sort-item {
    margin-right: 15px;
}
.el-icon-close {
    color: #A0A0A0;
    margin-left: 12px;
}
