
.judge-option {
    width: 100%;
    .el-radio {
        display: block;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .single-input {
        width: 200px;
    }
}
