
.fill-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .fill-input {
        width: 700px;
        margin: 0 12px;
    }
}
