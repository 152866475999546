
.main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.subject-ruleForm {
    padding-top: 20px;
    padding-right: 54px;
    ::v-deep {
        .el-form-item__label {
            color: #333;
            font-size: 16px;
        }
    }
    .subject-short-answer {
        display: none;
    }
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
/*单选框组 样式覆盖*/
.radioGroup-yellow {
    ::v-deep .el-radio-button {
        margin-left: 10px;
        .el-radio-button__inner {
            color: #333;
            border: 1px solid #DCDCDC;
            border-radius: 2px;
            padding: 9px 0;
            width: 62px;
            font-weight: normal;
            &:hover {
                color: #2DC079;
            }
        }
        &:first-child {
            margin-left: 0;
        }
        &:first-child, &:last-child {
            .el-radio-button__inner {
                border-radius: 0;
            }
        }
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #fff;
            background-color: #2DC079;
            border-color: #2DC079;
            box-shadow: -1px 0 0 0 #2DC079;
        }
        .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
            color: #666;
            background-color: #EDEDED;
            border-color: #D2D2D2;
        }
        .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner {
            background-color: #2DC079;
            border-color: #2DC079;
            color: #fff;
        }
    }
}
